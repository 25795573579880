import "styles/pages/page-blog.scss";

import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";

const Blog = ({ data: { allPosts, firstPost } }) => {
	return (
		<Layout headerColor="#0260DB">
			<Seo title="Blog" />
			<SubpageHeader
				image={require("assets/images/blog-top-bg.jpg").default}
				borderColor="#0260DB"
			/>

			<section className="section">
				<div className="container">
					<h2 className="section-title">Blog</h2>
				</div>
			</section>

			<section className="blog">
				<div className="container">
					<div className="blog-tile blog-tile--main">
						<div className="row align-items-center">
							<div className="col-md-6">
								<div className="blog-tile__inner blog-tile__inner--main">
									<h4>{firstPost.edges[0].node.title}</h4>
									<div
										dangerouslySetInnerHTML={{
											__html: firstPost.edges[0].node
												.excerpt,
										}}
									/>
									<Link
										to={`/blog/${firstPost.edges[0].node.slug}/`}
										className="button button--yellow"
									>
										Czytaj więcej
									</Link>
								</div>
							</div>
							<div className="col-md-6">
								<div
									className="blog-tile__thumb blog-tile__thumb--main"
									style={{
										backgroundImage: `url(${
											firstPost.edges[0].node
												.featuredImage &&
											firstPost.edges[0].node
												.featuredImage.node.sourceUrl
										})`,
									}}
								></div>
								<div className="blog-tile__info blog-tile__info--main">
									<div>
										<div className="blog-tile__user"></div>
									</div>
									<div>
										<span>Najnowszy</span>
										<data>
											{firstPost.edges[0].node.date
												.split("T")[0]
												.split("-")
												.reverse()
												.join(".")}
										</data>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						{allPosts?.edges?.map((item, index) => (
							<div className="col-md-4" key={index}>
								<div className="blog-tile">
									<div
										className="blog-tile__thumb"
										style={{
											backgroundImage: `url(${
												item.node.featuredImage &&
												item.node.featuredImage.node
													.sourceUrl
											})`,
										}}
									></div>
									<div className="blog-tile__info">
										<div>
											<div className="blog-tile__user"></div>
										</div>
										<data>
											{item.node.date
												.split("T")[0]
												.split("-")
												.reverse()
												.join(".")}
										</data>
									</div>
									<div className="blog-tile__inner">
										<h4>{item.node.title}</h4>
										<div
											dangerouslySetInnerHTML={{
												__html: item.node.excerpt,
											}}
										/>
										<Link
											to={`/blog/${item.node.slug}/`}
											className="button button--yellow"
										>
											Czytaj więcej
										</Link>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		</Layout>
	);
};

export const query = graphql`
	query {
		firstPost: allWpPost(limit: 1, sort: { fields: date, order: DESC }) {
			edges {
				node {
					slug
					title
					content
					excerpt
					date
					author {
						node {
							firstName
							lastName
							avatar {
								url
							}
						}
					}
					featuredImage {
						node {
							sourceUrl
						}
					}
					acfPost {
						postTopImage {
							sourceUrl
						}
					}
				}
			}
		}
		allPosts: allWpPost(sort: { fields: date, order: DESC }, skip: 1) {
			edges {
				node {
					slug
					title
					content
					excerpt
					date
					author {
						node {
							firstName
							lastName
							avatar {
								url
							}
						}
					}
					featuredImage {
						node {
							sourceUrl
						}
					}
					acfPost {
						postTopImage {
							sourceUrl
						}
					}
				}
			}
		}
	}
`;

export default Blog;
